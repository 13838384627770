*,
*::before,
*::after {
	box-sizing: border-box;
}

.spinner-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	background-color: black;
	opacity: 0.8;
	width: 100%;
	height: 100%;

	.spinner {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -50px;
		margin-left: -50px;
	}
}

.mt-2 {
	margin-top: 0.5rem;
}
.mb-2 {
	margin-bottom: 0.5rem;
}
.my-2 {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.ml-2 {
	margin-left: 0.5rem;
}
.mr-2 {
	margin-right: 0.5rem;
}
.mx-2 {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.p-2 {
	padding: 0.5rem;
}
.p-4 {
	padding: 1rem;
}

.mt-4 {
	margin-top: 1rem;
}
.my-4 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.row {
	display: flex;
	flex-wrap: wrap;
}

.w-50 {
	width: 100%;
	flex: 0 0 50%;
	max-width: 50%;
}

.w-100 {
	width: 100%;
}
